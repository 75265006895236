<template>

    <div class="notifications">
        <v-toolbar color="primary" dark flat>
            <v-btn icon @click="goBack">
                <v-icon large>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{$t(pageTitle)}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <page-transition>
            <loading v-if="isLoading"/>
            <template v-if="!isLoading">
                <router-view @onUpdatePageTitle="updatePageTitle"></router-view>
            </template>
        </page-transition>

        <v-alert type="error" icon="error_outline" v-if="isError">
            {{$t('notifications.problem_fetching')}}

        </v-alert>
    </div>

</template>

<script>
import {mapMutations} from 'vuex';
import PageTransition from '../../../components/Transitions/PageTransition';

export default {
    name: 'Notifications',
    data: () => ({
        pageTitle: 'bottom_navigation.notifications',
        isLoading: false,
        isError: false
    }),
    components: {PageTransition},
    methods: {
        ...mapMutations('notifications', ['setNotificationsSeen']),
        updatePageTitle(title) {
            this.pageTitle = title;
        },
        fetchNotifications() {
            this.isLoading = true;
            this.$store.dispatch('notifications/getAllNotifications').then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.isError = true;
                this.isLoading = false;
            });
        },
        fetchNotificationsFromStorage() {
            this.isLoading = true;
            this.$store.dispatch('notifications/getAllNotificationsFromIndexedDB').then(() => {
                this.isLoading = false;
            });
        },
        triggerNotificationsHasBeenSeen() {
            this.$store.dispatch('notifications/notificationsHasBeenSeen').then(() => {
                this.setNotificationsSeen();
            });
        },
        goBack() {
            this.$router.back();
        }
    },
    created() {
        if (this.isOnline) {
            this.fetchNotifications();
        }
        if (!this.isOnline) {
            this.fetchNotificationsFromStorage();
        }
    },
    beforeDestroy() {
        this.triggerNotificationsHasBeenSeen();
    }
};
</script>

<style lang="scss" scoped>
    .notification {
        border-bottom: 1px solid #d0d0d0;

        &:last-child {
            border-bottom: 0px;
        }
    }

    .notification--new {
        background-color: #e2edfe;
    }
</style>
