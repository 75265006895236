<template>
    <div class="notifications__list">
        <v-list class="notification-list notification-list__new">
            <v-list-item class="notification notification--new" v-for="(notification, index) in newNotifications" :key="notification.id" :to="{ name: 'notification-details', params: { id: notification.id }}" :class="{'u-border-bottom-none': (index + 1) === newNotifications.length }">
                <v-list-item-avatar>
                    <v-avatar color="primary" :size="40">
                        <span class="white--text" style="font-size:11px; font-weight: 700">{{notification.createdAt | formatDateAlternate }}</span>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{$t(notification.title)}} <span class="status-new ml-2"><span>{{$t("notifications.new" )}}</span></span></v-list-item-title>
                    <v-list-item-subtitle>{{$t(notification.body).substring(0, 100)}}...</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon>chevron_right</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list>

        <v-list class="notification-list">
            <v-list-item class="notification u-border-bottom" v-for="(notification, index) in oldNotifications" :key="notification.id" :to="{ name: 'notification-details', params: { id: notification.id }}" :class="{'u-border-bottom-none': (index + 1) === oldNotifications.length }">
                <v-list-item-avatar>
                    <v-avatar color="primary" :size="40">
                        <span class="white--text" style="font-size:11px; font-weight: 700">{{notification.createdAt | formatDateAlternate }}</span>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{$t(notification.title)}}</v-list-item-title>
                    <v-list-item-subtitle>{{$t(notification.body).substring(0, 100)}}...</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon>chevron_right</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list>

        <!-- Empty state -->
        <div class="text-center py-4" v-if="notifications.length === 0">
            <v-icon color="primary" :size="60">notifications_none</v-icon>
            <p>{{$t("notifications.none")}}</p>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
export default {
    name: 'NotificationList',
    computed: {
        ...mapState('notifications', ['notifications']),
        ...mapGetters('notifications', ['newNotifications', 'oldNotifications'])
    },
    mounted() {
        this.$emit('onUpdatePageTitle', 'bottom_navigation.notifications');
    }
};
</script>
