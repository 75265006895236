<template>
    <v-card flat class="notifications__detail">
        <loading v-if="isLoading"/>
        <template v-if="!isLoading">
            <v-card-title>{{ $t(notification.title) }}</v-card-title>
            <v-card-text>
                <small>{{ notification.createdAt | formatDateWithTime }}</small>
                <br/>
                {{ $t(notification.body) }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" class="mb-3" min-width="150" outlined rounded :to="{name: 'pending'}">
                    {{ $t("notification.go_to_pending") }}
                </v-btn>
            </v-card-actions>
        </template>
    </v-card>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'NotificationDetail',
    data: () => ({
        notification: null,
        isLoading: false,
        isError: false
    }),
    computed: {
        ...mapState('notifications', ['notifications'])
    },
    methods: {
        fetchNotification() {
            this.notification = this.notifications.find((notification) => notification.id === this.$route.params.id);
            this.$emit('onUpdatePageTitle', this.notification.title);
        },
        fetchNotifications() {
            this.isLoading = true;
            this.$store.dispatch('notifications/getAllNotifications').then(() => {
                this.isLoading = false;
                this.fetchNotification();
            }).catch(() => {
                this.isError = true;
            });
        }
    },
    created() {
        if (this.notifications.length === 0) {
            this.fetchNotifications();
        } else {
            this.fetchNotification();
        }
    }
};
</script>
